import React from 'react';
import { NextSeo } from 'next-seo';
import { useUserAgent } from 'next-useragent';
import { RootStoreProvider } from '../providers/RootStoreProvider';
import styles from '../styles/notFoundPage.module.sass';
import { Layout } from '../components/Layout';
import { SearchStoreProvider } from '../providers/SearchStoreProvider';

export default function Custom404({ uaString }) {
  const ua = useUserAgent(uaString);
  const { isMobile, isTablet } = ua;
  return (
    <SearchStoreProvider>
      <RootStoreProvider>
        <NextSeo title={'Ошибка 404: страница не найдена'} />
        <Layout isMobile={isMobile} isTablet={isTablet} isOutsPage>
          <div className={styles.content}>
            <div className={styles.header}>Ошибка 404</div>
            {isMobile ? (
              <div>
                <div className={styles.text}>
                  Увы, либо ссылка неверна, либо страница уже удалена.
                </div>
                <br />
                <div>
                  <div className={styles.text}>
                    Попробуйте перезагрузить страницу{' '}
                  </div>
                  <div className={styles.mobileText}>
                    <div className={styles.text}>или</div>

                    <a className={styles.textLink} href="/" rel={'nofollow'}>
                      {' '}
                      перейти на главную
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.text}>
                  Увы, либо ссылка неверна, либо страница уже удалена.
                </div>
                <br />
                <div className={styles.textBottom}>
                  <div className={styles.text}>
                    Попробуйте перезагрузить страницу{' '}
                  </div>
                  <div className={styles.mobileText}>
                    <div className={styles.text}>или</div>

                    <a className={styles.textLink} href="/" rel={'nofollow'}>
                      {' '}
                      перейти на главную
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div>
              <img
                src={!isMobile ? '/icons/404.svg' : '/icons/404mob.svg'}
                alt=""
              />
            </div>
          </div>
        </Layout>
      </RootStoreProvider>
    </SearchStoreProvider>
  );
}
